import ckeditorconfig from "@/libs/ckeditorconfig";

export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "article",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "thumbnailId",
    initKey: "thumbnail",
    label: "field.thumbnail",
    type: "async-single-image",
    path: "article",
    pw: 1900,
    ph: 1271,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "articleCategoryId",
    label: "field.articleCategory",
    rules: "required",
    type: "asyn-single-selection",
    repository: "articleCategory",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer|min_value:1",
    type: "currency",
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "shortDescriptionEn",
    label: "field.shortDescriptionEn",
    rules: "required|max:300",
    type: "textarea",
  },
  {
    key: "shortDescriptionKm",
    label: "field.shortDescriptionKm",
    rules: "required|max:300",
    type: "textarea",
  },
  {
    label: "breadcrumb.articleContentEn",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "required|max:3000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.articleContentKm",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "required|max:10000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.articleGallery",
    icon: "ImageIcon",
    type: "divider",
    hasDescriptionDimension: true,
    pw: 1920,
    ph: 880,
  },
  {
    key: "articleGalleries",
    hideLabel: true,
    cols: 12,
  },
  {
    key: "isHomeFeature",
    label: "field.homeFeature",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "isFeature",
    label: "field.feature",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
